import React from "react"
import { Row, Col } from 'reactstrap'
import { renderRichText } from "gatsby-source-contentful/rich-text"

import { parseOptions } from '../../utils/praseOptions';

import '../../styles/pages/das-team.scss';

const Member = (props) => {
  return (
    <Row className="member">
      {props?.portraitBild?.file?.url && (
        <Col className="member-img" xs="12" sm="6" md="3" lg="2">
          <img src={props.portraitBild.file.url} alt={props?.portraitBild?.file?.fileName} />
        </Col>
      )}
      <Col className="member-text" xs="12"  sm="6" md="9" lg="10">
        {props?.name && (
          <h3 className="member-name" style={{marginBottom:'20px'}}>{props.name}</h3>
        )}
        
        <div  className="data-row">
          <span style={{fontSize:'20px'}}>Funktion:</span>
          <p>{renderRichText(props.funktion, parseOptions)}</p>
        </div>
       
        {/* <div className="data-row">
          <span>Mensch:</span>
          <p>{renderRichText(props.mensch, parseOptions)}</p>
        </div> */}
        <br/>
        <div className="data-row">
          <span style={{fontSize:'20px'}}>Email: </span>

          {props?.mail && (
           
          <a style={{color:'black'}} href={`mailto:${props.mail}`}>
            {props.mail}</a> 
          ) }
        </div>
      </Col>
    </Row>
  )
}

export default Member
