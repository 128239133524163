// import React from 'react'
// import Img from 'gatsby-image'

// // import companyThumbnail from "../images/fahr_erlebnis_weiss_transparent.png"


// const HeroTeam = ({ image}) => {
//     return (
//         <section className="hero">
//             <div className="hero-description">
//             {/* <img
//             style={{display:'hidden'}}
//                 src={companyThumbnail}
//                 alt="Company Thumbnail"
//                 className="img-head"
//               />     */}
//                 {/* <h1 className='hero-team-title'>{title}</h1> */}
//             </div >
           

//             {image && (
//                 <Img fluid={image} className="hero-image"/>
//             )}


         
//         </section>
//     )
// }

// export default HeroTeam;


import React from "react"
import { Container } from "reactstrap"

import "../styles/components/hero.scss"

import companyThumbnail from "../images/fahr_erlebnis_weiss_transparent.png"


const HeroTeam = ({
    title="SEMINARRÄUME",
    image,
    fullScreen=false
}) => {
  return (
    <div
      // className="heroGastro"
      id="hero"
      style={{
        backgroundRepeat:'no-repeat',
        background: `linear-gradient( rgba(0, 0, 0, 0.11), rgba(0, 0, 0, 0.11) ), 
        url(${image?.src})`, height: fullScreen ? '100vh' : '80vh' ,
        backgroundSize:'cover',
        backgroundPosition:'center',
      }}
    >
      <section className="h-100 d-flex align-items-center text-center bg-dark-100">
        <Container>
        {/* <img
            src={companyThumbnail}
            alt="fahr erlebnis logo"
            className="img-head-homeGastro"
        />  
          <main className="hero-contentGastro">
          <h1 className="titleGastro">{title}</h1>
            
          </main> */}
        </Container>
      </section>
    </div>
  )
}

export default HeroTeam
